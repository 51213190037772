

























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import AWHero from '@/components/anders-wonen/Hero.vue'

export default defineComponent({
  name: 'AndersWonen',
  components: {
    AWHero,
  },
  props: {},

  setup() {
    const components = {
      highlighted2: () =>
        import('@/components/flexible/HighlightedSection.vue'),
      galleryWtext: () => import('@/components/anders-wonen/Gallery.vue'),
      linkedPosts: () => import('@/components/flexible/CardSlider.vue'),
      video: () => import('@/components/flexible/Video.vue'),
      videoSlider: () => import('@/components/anders-wonen/VideoSlider.vue'),
      moodSlider: () => import('@/components/anders-wonen/MoodSlider.vue'),
      ctaMoodboard: () => import('@/components/cta/Moodboard.vue'),
      ctaStories: () => import('@/components/cta/Stories.vue'),
    }

    return {
      ...useGetters(['content']),
      components,
    }
  },
})
